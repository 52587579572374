import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const posts = [
  {
    title: 'fmt.Println("Hello, World")',
    href: '#',
    description:
      'Currently rebuilding this page. Back soon...',
    date: 'May 13, 2021',
    datetime: '2021-05-13',
  },
  // {
  //   title: 'How to use search engine optimization to drive sales',
  //   href: '#',
  //   description: 'Optio cum necessitatibus dolor voluptatum provident commodi et. Qui aperiam fugiat nemo cumque.',
  //   date: 'Mar 10, 2020',
  //   datetime: '2020-03-10',
  // },
  // {
  //   title: 'Improve your customer experience',
  //   href: '#',
  //   description:
  //     'Cupiditate maiores ullam eveniet adipisci in doloribus nulla minus. Voluptas iusto libero adipisci rem et corporis.',
  //   date: 'Feb 12, 2020',
  //   datetime: '2020-02-12',
  // },
  // {
  //   title: 'Writing effective landing page copy',
  //   href: '#',
  //   description:
  //     'Ipsum voluptates quia doloremque culpa qui eius. Id qui id officia molestias quaerat deleniti. Qui facere numquam autem libero quae cupiditate asperiores vitae cupiditate. Cumque id deleniti explicabo.',
  //   date: 'Jan 29, 2020',
  //   datetime: '2020-01-29',
  // },
]
const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    {posts.map((post) => (
      <div key={post.title}>
        <p className="text-sm text-gray-500">
          <time dateTime={post.datetime}>{post.date}</time>
        </p>
        <a href="#" className="mt-2 block">
          <p className="text-xl font-semibold text-gray-900">{post.title}</p>
          <p className="mt-3 text-base text-gray-500">{post.description}</p>
        </a>
        <div className="mt-3">
          {/* <a href={post.href} className="text-base font-semibold text-gray-600 hover:text-gray-500">
            Read full story
                </a> */}
        </div>
      </div>
    ))}
  </Layout>
)

export default IndexPage

